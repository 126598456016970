import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 2,
  "title": "Wordpress",
  "subtitle": "Accept MitID, Swedish BankID, Norwegian BankID and other eID logins with Wordpress and Criipto Verify"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Write your own integration`}</h2>
    <p>{`Criipto Verify is an OpenID Provider so if you wish to write your own wordpress integration with PHP
we can recommend the `}<a parentName="p" {...{
        "href": "https://github.com/jumbojett/OpenID-Connect-PHP"
      }}>{`jumbojett OpenID library for PHP`}</a>{`.`}</p>
    <p>{`The library takes care of the major OIDC details.`}</p>
    <h2>{`Wordpress plugin`}</h2>
    <p>{`Criipto does not offer any Wordpress plugin directly but miniOrange offers a `}<a parentName="p" {...{
        "href": "https://wordpress.org/plugins/miniorange-login-with-eve-online-google-facebook/"
      }}>{`WordPress OpenID Connect Client`}</a>{`
for Wordpress and has also written a `}<a parentName="p" {...{
        "href": "https://plugins.miniorange.com/wordpress-oauth-openid-connect-single-sign-on-sso-using-criipto"
      }}>{`tutorial specifically for Criipto Verify`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      